var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"offset-x":!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm),"offset-y":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm),"content-class":"select-floating","nudge-left":!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm) ? 10 : 0,"nudge-top":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm) ? 20 : 0,"left":!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm),"top":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"color":"white","disabled":_vm.open,"content-class":"tooltip-white","transition":"fade-transition","left":"","maxWidth":"240"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"toolbar-button",attrs:{"color":"gray","dark":""},on:{"click":function($event){_vm.open = !_vm.open}}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('i',{staticClass:"resolution-controll-button-icon",class:{
               'button-resolution-icon-boundary-1': _vm.activeResolution === 'admin1',
               'button-resolution-icon-boundary-2': _vm.activeResolution === 'admin2',
               'button-resolution-icon-hex-1': _vm.activeResolution === 'hex-1km',
               'button-resolution-icon-hex-5': _vm.activeResolution === 'hex-5km',
               'button-resolution-icon-hex-10': _vm.activeResolution === 'hex-10km'
             }})])]}}],null,true)},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t('gis.toolbar.resolution[0]')))]),_vm._v(" "+_vm._s(_vm.$t('gis.toolbar.resolution[1]'))+" ")])])]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"background-grey"},[_c('v-card-text',{},[_c('div',{staticClass:"mb-2"},[_c('h4',[_vm._v(_vm._s(_vm.$t('gis.toolbar.hexbins')))]),_c('v-btn-toggle',{attrs:{"value":_vm.activeHexResolution}},[_c('v-btn',{staticClass:"button-resolution-controller",on:{"click":function($event){return _vm.handleResolutionChange('hex-1km')}}},[_vm._v(" 1 km "),_c('i',{staticClass:"button-resolution-icon button-resolution-icon-hex-1"})]),_c('v-btn',{staticClass:"button-resolution-controller",on:{"click":function($event){return _vm.handleResolutionChange('hex-5km')}}},[_vm._v(" 5 km "),_c('i',{staticClass:"button-resolution-icon button-resolution-icon-hex-5"})]),_c('v-btn',{staticClass:"button-resolution-controller",on:{"click":function($event){return _vm.handleResolutionChange('hex-10km')}}},[_vm._v(" 10 km "),_c('i',{staticClass:"button-resolution-icon button-resolution-icon-hex-10"})])],1)],1),_c('div',{},[_c('h4',[_vm._v(_vm._s(_vm.$t('gis.toolbar.administrativeRegions')))]),_c('v-btn-toggle',{staticClass:"d-flex",attrs:{"value":_vm.activeAdminResolution}},[_c('v-btn',{staticClass:"button-adm button-resolution-controller",on:{"click":function($event){return _vm.handleResolutionChange('admin1')}}},[_vm._v(" "+_vm._s(_vm.$t('gis.toolbar.level1'))+" "),_c('i',{staticClass:"button-resolution-icon button-resolution-icon-boundary-1 "})]),_c('v-btn',{staticClass:"button-adm button-resolution-controller",on:{"click":function($event){return _vm.handleResolutionChange('admin2')}}},[_vm._v(" "+_vm._s(_vm.$t('gis.toolbar.level2'))+" "),_c('i',{staticClass:"button-resolution-icon button-resolution-icon-boundary-2"})])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }