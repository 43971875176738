var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"offset-x":!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm),"offset-y":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm),"content-class":"select-floating","nudge-left":!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm) ? 10 : 0,"nudge-top":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm) ? 20 : 0,"left":!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm),"top":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"color":"white","disabled":_vm.open,"content-class":"tooltip-white","transition":"fade-transition","left":"","maxWidth":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"toolbar-button",attrs:{"color":"gray","disabled":_vm.disabled,"dark":""},on:{"click":function($event){_vm.open = !_vm.open}}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('i',{staticClass:"color-scheme-icon",class:_vm.getColorIcon(_vm.activeColor)})])],1)]}}],null,true)},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t('gis.toolbar.color[0]')))]),_vm._v(" "+_vm._s(_vm.$t('gis.toolbar.color[1]'))+" ")])])]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('div',{staticClass:"select"},[_c('v-select',{staticClass:"color-scheme-select",attrs:{"value":_vm.activeColor,"rounded":"","outlined":"","dense":"","hide-details":"","items":_vm.colors},on:{"change":_vm.changeColorScheme},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('i',{staticClass:"color-selection-select mr-2",class:_vm.getColorIcon(item.value)}),_c('span',[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"item",fn:function(data){return [_c('i',{staticClass:"color-selection-select mr-2",class:_vm.getColorIcon(data.item.value)}),_vm._v(" "+_vm._s(data.item.text)+" ")]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }