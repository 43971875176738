var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"offset-x":!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm),"offset-y":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm),"content-class":"select-floating","nudge-left":!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm) ? 10 : 0,"nudge-top":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm) ? 20 : 0,"left":!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm),"top":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"color":"white","disabled":_vm.open,"content-class":"tooltip-white","transition":"fade-transition","left":!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm),"top":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm),"maxWidth":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"toolbar-button",attrs:{"color":"gray","dark":""},on:{"click":function($event){_vm.open = !_vm.open}}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('i',{staticClass:"country-icon"})])]}}],null,true)},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t('gis.toolbar.countrySelect[0]')))]),_vm._v(" "+_vm._s(_vm.$t('gis.toolbar.countrySelect[1]'))+" ")])])]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('div',{staticClass:"select"},[_c('v-autocomplete',{staticClass:"map-country-select",attrs:{"rounded":"","outlined":"","placeholder":"Search country or region","hide-selected":"","dense":"","filter":_vm.countryFilter,"hide-details":"","items":_vm.countries,"item-text":"GID_0","item-value":"NAME_0","return-object":""},on:{"change":_vm.countrySelect},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"map-country-select_selection"},[(item.flagCode)?_c('i',{staticClass:"flag-icon select_icon mr-2",class:'flag-icon-' + _vm.computeFlagCode(item.flagCode)}):_vm._e(),_vm._v(" "+_vm._s(item.id ? _vm.$t('regions.' + item.id) : _vm.computeCountryName(item.GID_0))+" ")])]}},{key:"item",fn:function(data){return [_c('i',{staticClass:"flag-icon select_icon mr-2",class:'flag-icon-' + _vm.computeFlagCode(data.item.flagCode)}),_vm._v(" "+_vm._s(data.item.id ? _vm.$t('regions.' + data.item.id) : _vm.computeCountryName(data.item.GID_0))+" ")]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }