var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"content-class":"select-floating","offset-x":!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm),"offset-y":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm),"nudge-left":!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm) ? 10 : 0,"nudge-top":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm) ? 20 : 0,"left":!(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm),"top":(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"color":"white","disabled":_vm.open,"content-class":"tooltip-white","transition":"fade-transition","left":"","maxWidth":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"toolbar-button",attrs:{"color":"gray","dark":""},on:{"click":function($event){_vm.open = !_vm.open}}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('i',{staticClass:"basemap-selector-icon",class:_vm.getMapIcon(_vm.basemap)})])]}}],null,true)},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t('gis.toolbar.basemap[0]')))]),_vm._v(" "+_vm._s(_vm.$t('gis.toolbar.basemap[1]'))+" ")])])]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('div',{staticClass:"select"},[_c('v-select',{staticClass:"color-scheme-select undp-select",attrs:{"value":_vm.basemap,"outlined":"","dense":"","hide-details":"","items":_vm.basemaps},on:{"change":_vm.changeBasemap},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('i',{staticClass:"base-selection-select mr-2",class:_vm.getMapIcon(item)}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('gis.toolbar.'+item))+" ")])]}},{key:"item",fn:function(data){return [_c('i',{staticClass:"base-selection-select mr-2",class:_vm.getMapIcon(data.item)}),_vm._v(" "+_vm._s(_vm.$t('gis.toolbar.'+data.item))+" ")]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }